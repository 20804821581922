import "chart.js/auto"; // Necessary for Chart.js
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import menuLeft from "../../assets/images/menu-left.png";
import search from "../../assets/images/search-normal.png";
import FooterNavigation from "../FooterNavigation";
import StatisticsChart from "./StatisticsChart";
import Shopping from "../../assets/images/bag-tick.png";
import Monitor from "../../assets/images/monitor.png";
import Food from "../../assets/images/food-dinner-svgrepo-com.png";
import { FaMagnifyingGlass } from "react-icons/fa6";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Data for the bar chart
  //   const data = {
  //     labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  //     datasets: [
  //       {
  //         label: "Hgb",
  //         data: [90, 110, 150, 85, 95, 100, 105],
  //         backgroundColor: (context) => {
  //           const index = context.dataIndex;
  //           return index === 2 ? "#8AB53E" : "#D3D3D3"; // Highlight Wednesday
  //         },
  //         borderRadius: 8,
  //       },
  //     ],
  //   };

  //   const options = {
  //     scales: {
  //       y: {
  //         beginAtZero: true,
  //         max: 160,
  //       },
  //     },
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //   };
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    EMPRESA: "",
    RHID: "",
    DT_ADMISSAO: "",
    DT_INICIO: "2024-09-01",
    DT_FIM: "2024-09-05",
    ESTADO: "A",
    TOTAL_DESPESA: 2500.75,
    CD_MOEDA: "USD",
    NR_DOCUMENTO: "INV-123456",
    ANO: 2024,
    MES: 9,
    ID_PERIODO: 1,
    SEQ_: 10,
    CD_RUBRICA: "TRVL",
    CD_ENT_INT: "100000",
    ID_WKF: 200,
    INSERTED_BY: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);

      // Ensure DT_ADMISSAO is included
      setFormData((prevData) => ({
        ...prevData,
        EMPRESA: decodedToken.EMPRESA || "DEMO",
        RHID: decodedToken.RHID,
        DT_ADMISSAO: decodedToken.DT_ADMISSAO || "", // Use an empty string as fallback
        INSERTED_BY: decodedToken.RHID,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/expenses/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const createdExpense = await response.json(); // Get the newly created expense data from the server
        console.log("Created Expense:", createdExpense); // Debugging: Check the content of the createdExpense

        // Ensure the createdExpense contains the expected data
        const newExpense = {
          icon: determineIcon(createdExpense),
          label: createdExpense.EMPRESA || "No description",
          date: createdExpense.DT_INSERTED
            ? new Date(createdExpense.DT_INSERTED).toLocaleDateString()
            : "Invalid Date",
          amount: createdExpense.TOTAL_DESPESA
            ? `$${createdExpense.TOTAL_DESPESA}`
            : "$0.00",
        };

        setExpenses((prevExpenses) => [newExpense, ...prevExpenses]); // Add the new expense to the top of the list

        toast("Expense created successfully!");
        setShowModal(false);
      } else {
        const errorData = await response.json();
        console.error("Error creating expense:", errorData);
        alert("Failed to create expense.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the expense.");
    }
  };

  const iconMap = {
    DEMO: Shopping,
    DEMO1: Monitor,
    DEMO2: Food,
    DEM3: Monitor,
  };

  // Fetch expenses from API
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const token = localStorage.getItem("token"); // Get the token from localStorage
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await fetch(`${apiUrl}/api/expenses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.expenses && data.expenses.length > 0) {
            const fetchedExpenses = data.expenses.map((expense) => ({
              icon: determineIcon(expense),
              label: expense.EMPRESA || "No description",
              date: new Date(expense.DT_INSERTED).toLocaleDateString(),
              amount: `$${expense.TOTAL_DESPESA}`,
            }));
            setExpenses(fetchedExpenses); // Set expenses if they exist
          } else {
            setExpenses([]); // Set an empty array if no expenses are found
          }
        } else {
          const errorText = await response.text(); // Capture the error response from the server
          console.error("Failed to fetch expenses:", errorText);
          setExpenses([]); // Set an empty array to handle error cases gracefully
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
        setExpenses([]); // Set an empty array in case of an error
      }
    };

    fetchExpenses();
  }, [apiUrl]);

  // Function to determine icon based on expense description or other logic
  // Function to determine icon based on expense description or other logic
  const determineIcon = (expense) => {
    const description = expense.EMPRESA || ""; // Fallback to an empty string if DESCRICAO is undefined

    if (description.toLowerCase().includes("DEMO")) {
      return "DEMO";
    } else if (description.toLowerCase().includes("DEMO1")) {
      return "DEMO1";
    } else if (description.toLowerCase().includes("DEMO2")) {
      return "DEMO2";
    } else if (description.toLowerCase().includes("DEMO3")) {
      return "DEMO3";
    } else {
      return "DEMO"; // Default icon
    }
  };

  return (
    <>
      <CommonHeaderWithMenu title="Expenses" />{" "}
      <div className="p-4 mb-20">
        {/* <div className=" bg-tranparent  rounded-b-lg"> */}
        {/* Top Section with Icons and Title */}
        {/* <div className="flex items-center justify-between mb-4"> */}
        {/* Menu Icon */}
        {/* <div
              className="flex items-center justify-center w-10 h-10
         bg-transparent"
            >
              <img src={menuLeft} alt="" />
            </div> */}

        {/* Title */}
        {/* <h1 className="text-xl font-semibold text-[#373737]"></h1> */}

        {/* Notification Icon */}
        {/* <div
              className="relative flex items-center justify-center 
           "
            > */}
        {/* <FaMagnifyingGlass className="mr-2 size-6" /> */}

        {/* <img src={search} alt="" className="h-7 w-7 mr-3 " /> */}
        {/* <FiBell className="text-xl text-gray-600" />
          <span className="absolute top-1 right-1 w-2.5 h-2.5 bg-green-400 rounded-full"></span> */}
        {/* </div> */}
        {/* </div> */}

        {/* Search Bar */}
        {/* </div> */}
        {/* Header Section */}
        <Link to="/employee" className="ml-[280px] mb-6 text-[#8AB53E]">
          <u>Go back</u>
        </Link>
        {/* Statistics Section */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[18px] font-semibold text-gray-800">
              Statistics
            </h2>
            <select
              className="bg-[#8AB53E] text-white text-sm font-semibold rounded p-1 border-[#8AB53E]   focus:outline-none
                focus:ring-[#8AB53E] focus:border-[#8AB53E]"
            >
              <option className="">Monthly</option>
              <option>Weekly</option>
            </select>
          </div>
        </div>
        <StatisticsChart />
        <h1 className="text-[#373737] text-[18px] font-semibold mb-3 ">
          Expenses
        </h1>
        {/* Expenses List Section */}
        <div className="space-y-4 ">
          {expenses.map((expense, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-white p-3 rounded-lg "
            >
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 rounded-md bg-[#F8F8F8] border border-[#D9D9D9] flex items-center justify-center">
                  <img
                    src={iconMap[expense.icon]}
                    alt={expense.label}
                    className="w-6 h-6"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-800">
                    {expense.label}
                  </h3>
                  <p className="text-xs text-gray-500">{expense.date}</p>
                </div>
              </div>
              <p className="text-[#8AB53E] font-semibold">{expense.amount}</p>
            </div>
          ))}
        </div>

        {/* Floating Action Button */}
        <button
          // onClick={() => setShowModal(true)}
          className="fixed bottom-16 right-4 bg-[#8AB53E] p-4 rounded-full text-white shadow-lg"
        >
          <Link to="/expenses/new">
            <FiPlus className="text-2xl" />
          </Link>
        </button>
      </div>
      <FooterNavigation />
      {showModal && (
        <div
          className="fixed inset-0 bg-black flex justify-center items-center
         bg-opacity-75 z-50"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-semibold mb-4">Create Expense</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Empresa:
                </label>
                <input
                  type="text"
                  name="EMPRESA"
                  value={formData.EMPRESA}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              {/* Add more form fields similar to the one above for each property */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Total Despesa:
                </label>
                <input
                  type="number"
                  name="TOTAL_DESPESA"
                  value={formData.TOTAL_DESPESA}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              {/* Continue adding other form fields as needed */}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-4 bg-gray-300 px-4 py-2 rounded"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#8AB53E] px-4 py-2 rounded text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Expenses;
