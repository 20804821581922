import React from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import UserCommunications from "./UserCommunications";
import LegislationData from "./LegislationData";
import NewsData from "./NewsData";
import FaqData from "./FaqData";
import OnboardingData from "./OnboardingData";

const Communication = () => {
  return (
    <>
      <CommonHeaderWithMenu title="Communication" />
      <div className="mb-16 bg-white rounded-lg shadow-md mt-6">
        <UserCommunications />
        <LegislationData />
        <NewsData />
        <FaqData />
        <OnboardingData />
      </div>
      <FooterNavigation />
    </>
  );
};

export default Communication;
