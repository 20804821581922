import React, { useEffect, useState } from "react";

const EmployeeData = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/cadastre/employee/data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setEmployeeData(data.employeeData[0]); // Assuming you only get one record back
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  if (!employeeData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md mt-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Employee Information
        </h2>
        <div className="flex justify-center mb-6">
          {/* <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
            <img
              src={employeeData.IMAGE_URL || "default-profile.png"}
              alt="User Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </div> */}
        </div>
        <table className="table-auto w-full border-collapse">
          <tbody>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600">Company</th>
              <td className="px-4 py-2">{employeeData.EMPRESA}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600">RHID</th>
              <td className="px-4 py-2">{employeeData.RHID}</td>
            </tr>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600">
                Admission Date
              </th>
              <td className="px-4 py-2">{employeeData.DT_ADMISSAO}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600">
                Situation Code
              </th>
              <td className="px-4 py-2">{employeeData.CD_SITUACAO}</td>
            </tr>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600">Point</th>
              <td className="px-4 py-2">{employeeData.PONTO}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600">Phone</th>
              <td className="px-4 py-2">{employeeData.TELEFONE}</td>
            </tr>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-semibold text-gray-600">
                Extension
              </th>
              <td className="px-4 py-2">{employeeData.EXTENSAO}</td>
            </tr>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-600">
                Supervisor RHID
              </th>
              <td className="px-4 py-2">{employeeData.RHID_SUPERVISOR}</td>
            </tr>
            {/* Add more fields as needed */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeData;
