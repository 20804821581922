import React, { useState, useEffect } from "react";
import FooterNavigation from "../FooterNavigation";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom"; // Ensure correct navigation
import menuLeft from "../../assets/images/menu-left.png";
import cardReceive from "../../assets/images/card-receive.png";
import cardSend from "../../assets/images/card-send.png";
import Sms from "../../assets/images/sms.png";
import plusVector from "../../assets/images/Vector.png";
import { FaMagnifyingGlass } from "react-icons/fa6";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";

const Tickets = () => {
  const [activeTab, setActiveTab] = useState("received");
  const [expanded, setExpanded] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState("");

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Fetch tickets when the component mounts
  useEffect(() => {
    const fetchTickets = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage

      // console.log("Token on create page:", token);

      if (!token) {
        console.error("No token found, user is not authenticated.");
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/etickets/list`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Use token in Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTickets(data.tickets); // Assume the response contains an array of tickets
        } else {
          const errorData = await response.json();
          setError(errorData.message || "Failed to fetch tickets.");
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
        setError("An unexpected error occurred. Please try again.");
      }
    };

    fetchTickets();
  }, [apiUrl]);

  return (
    <div>
      <CommonHeaderWithMenu title="E-Tickets" />
      <div className="bg-transparent rounded-b-lg">
        {/* <div className="flex items-center justify-between mb-4">
          <div className="flex items-center justify-center w-10 h-10 bg-transparent">
            <img src={menuLeft} alt="" />
          </div>
          <h1 className="text-xl font-semibold text-[#373737]"></h1>
          <div className="relative flex items-center justify-center">
            <FaMagnifyingGlass className="mr-2 size-6" />
          </div>
        </div> */}
      </div>

      <div className="p-4 mb-32">
        <div className="flex mb-6 bg-[#F8F8F8] border border-[#D9D9D9] p-1 rounded-md">
          <button
            className={`flex items-center justify-center w-1/2 px-4 py-2 rounded-lg text-sm font-semibold ${
              activeTab === "received"
                ? "bg-[#8AB53E] text-white"
                : "bg-gray-100 text-gray-600"
            }`}
            onClick={() => setActiveTab("received")}
          >
            <img src={cardReceive} alt="" className="inline-block mr-2" />
            Received
          </button>
          <button
            className={`flex items-center justify-center w-1/2 px-4 py-2 rounded-lg text-sm font-semibold ${
              activeTab === "sent"
                ? "bg-[#8AB53E] text-white"
                : "bg-gray-100 text-gray-600"
            }`}
            onClick={() => setActiveTab("sent")}
          >
            <img src={cardSend} alt="" className="inline-block mr-2" />
            Sent
          </button>
        </div>

        {error && <div className="text-center text-gray-500">{error}</div>}

        {activeTab === "received" && (
          <div className="space-y-4">
            {tickets.length > 0 ? (
              tickets.map((ticket, index) => (
                <div
                  key={ticket.ID_ETICKET}
                  className={`p-3 rounded-lg border ${
                    expanded === index ? "border-[#8AB53E]" : "border-[#D9D9D9]"
                  } bg-[#F8F8F8]`}
                >
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <div className="flex items-center">
                      <img src={Sms} alt="" className="mr-1" />
                      <div>
                        <span className="text-[12px] font-semibold">
                          {ticket.ASSUNTO}
                        </span>
                        <br />
                        <span className="text-[10px] text-gray-500">
                          {ticket.DT_ABERTURA}
                        </span>
                      </div>
                    </div>
                    {expanded === index ? (
                      <FiChevronUp className="text-gray-600 ml-2" />
                    ) : (
                      <FiChevronDown className="text-gray-600 " />
                    )}
                  </div>
                  {expanded === index && (
                    <div className="mt-4 text-sm text-gray-600">
                      <p>{ticket.DESCRICAO}</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">
                No tickets available.
              </div>
            )}
          </div>
        )}

        {activeTab === "sent" && (
          <div className="space-y-4">
            {tickets.length > 0 ? (
              tickets.map((ticket, index) => (
                <Link
                  key={ticket.ID_ETICKET}
                  to={`/e-ticketsdetails/${ticket.ID_ETICKET}`}
                >
                  <div
                    className={`p-3 rounded-lg border ${
                      expanded === index
                        ? "border-[#8AB53E]"
                        : "border-[#D9D9D9]"
                    } bg-[#F8F8F8] mb-4`}
                  >
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => toggleExpand(index)}
                    >
                      <div className="flex items-center">
                        <img src={Sms} alt="" className="mr-1" />
                        <div>
                          <span className="text-[12px] font-semibold">
                            {ticket.ASSUNTO}
                          </span>
                          <br />
                          <span className="text-[10px] text-gray-500">
                            {ticket.DT_ABERTURA}
                          </span>
                        </div>
                      </div>
                      {expanded === index ? (
                        <FiChevronUp className="text-gray-600 ml-2" />
                      ) : (
                        <FiChevronDown className="text-gray-600 " />
                      )}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="text-center text-gray-500">
                No tickets available.
              </div>
            )}
          </div>
        )}

        <div className="fixed bottom-20 right-4">
          <Link to="/new-tickets">
            <img
              src={plusVector}
              alt="Plus Icon"
              className="bg-[#8AB53E] p-3 rounded-full shadow-xl"
            />
          </Link>
        </div>
      </div>
      <FooterNavigation />
    </div>
  );
};

export default Tickets;
