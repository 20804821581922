import { createBrowserRouter, Link } from "react-router-dom";
import App from "../App";
import Login from "../shared/Login";
import Signup from "../shared/Signup";
import Employee from "../pages/employee/Employee";
import Notification from "../pages/notifications/Notification";
import Tickets from "../pages/tickets/Tickets";
import NewTickets from "../pages/tickets/NewTickets";
import ETicketDetails from "../pages/tickets/ETicketDetails";
import ClockMarking from "../pages/absenses/ClockMarking";
import Absences from "../pages/absenses/Absences";
import Expenses from "../pages/expenses/Expenses";
import Settings from "../component/settings/Settings";
import AddExpense from "../pages/expenses/AddExpense";
import Communication from "../pages/communication/Communication";
import Cadastre from "../pages/cadastre/Cadastre";
import Outputs from "../pages/outputs/Outputs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Signup />,
  },
  {
    path: "/employee",
    element: <Employee />,
  },
  {
    path: "/notification",
    element: <Notification />,
  },
  {
    path: "/tickets",
    element: <Tickets />,
  },
  {
    path: "/new-tickets",
    element: <NewTickets />,
  },
  {
    path: "/e-ticketsdetails/:ticketId", // Ensure this dynamic route is correctly defined
    element: <ETicketDetails />,
  },
  {
    path: "/absense-clockmarking",
    element: <ClockMarking />,
  },
  {
    path: "/absenses",
    element: <Absences />,
  },
  {
    path: "/expanses",
    element: <Expenses />,
  },
  {
    path: "/expenses/new",
    element: <AddExpense />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/communication",
    element: <Communication />,
  },
  {
    path: "/cadstre",
    element: <Cadastre />,
  },
  {
    path: "/outputs",
    element: <Outputs />,
  },
  {
    path: "*",
    element: (
      <>
        <p className="text-center text-5xl text-red-500 font-bold flex mx-auto mt-20">
          404: Page Not Found
        </p>
        <Link to="/employee" className="text-center font-bold ml-40 ">
          <u>Go Back</u>
        </Link>
      </>
    ),
  },
]);
export default router;
