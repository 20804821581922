import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { toast } from "react-toastify";
import FooterNavigation from "../../pages/FooterNavigation";
import defaultUserImage from "../../assets/images/avater.png";

const Settings = () => {
  const [userImage, setUserImage] = useState(null); // State to manage user image
  const [showLogout, setShowLogout] = useState(false); // State to toggle logout option visibility
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent
      });

      if (response.ok) {
        localStorage.removeItem("token"); // Remove the token from localStorage
        // toast.success("Logout successful!");
        navigate("/"); // Redirect to login page after logout
      } else {
        toast.error("Logout failed");
      }
    } catch (error) {
      toast.error("An error occurred during logout");
      console.error("An error occurred during logout", error);
    }
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setUserImage(reader.result); // Set the uploaded image
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="bg-white text-black h-screen p-4 mb-32">
        <h1 className="text-2xl font-semibold mb-6">Settings</h1>

        {/* User Image Section */}
        <div className="flex items-center mb-8">
          <img
            src={userImage || defaultUserImage}
            alt="User"
            className="w-16 h-16 rounded-full object-cover border-2 border-gray-300 mr-4"
          />
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Change Profile Picture
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="mt-2"
            />
          </div>
        </div>

        <div className="space-y-6 mb-32">
          {/* Main Settings Section */}
          <div className="space-y-4 bg-[#f0f0f0] p-4 rounded-lg">
            <Link
              to="/change-profile"
              className="flex justify-between items-center py-2"
            >
              <span>Change Profile</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/change-password"
              className="flex justify-between items-center py-2"
            >
              <span>Change Password</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/activate-facial-recognition"
              className="flex justify-between items-center py-2"
            >
              <span>Activate Facial Recognition</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/change-language"
              className="flex justify-between items-center py-2"
            >
              <span>Change Language</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/activate-push-notifications"
              className="flex justify-between items-center py-2"
            >
              <span>Activate Push Notifications</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/configure-theme"
              className="flex justify-between items-center py-2"
            >
              <span>Configure Theme</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/disable-push-notifications"
              className="flex justify-between items-center py-2"
            >
              <span>Disable Push Notifications</span>
              <FiChevronRight />
            </Link>
          </div>

          {/* Logout Section */}
          <div className="space-y-4 bg-[#f0f0f0] p-4 rounded-lg">
            <button
              onClick={handleLogout}
              className="flex justify-between items-center py-2 w-full text-left text-red-500"
            >
              <span>Logout</span>
              <FiChevronRight />
            </button>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default Settings;
