import React, { useEffect, useState } from "react";
import { FaFileAlt, FaTimes } from "react-icons/fa";
import { FaAddressBook, FaEnvelope, FaWallet } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";

const CommonHeaderWithMenu = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${
          isSticky ? "fixed top-0 left-0 right-0 z-50 shadow-md" : ""
        } bg-white p-4 transition-all duration-300 ease-in-out`}
        style={isSticky ? { marginBottom: "64px" } : {}} // Adjust margin for sticky header
      >
        <div className="flex items-center justify-between">
          {/* Menu Icon */}
          <div
            className="flex items-center justify-center w-10 h-10 bg-transparent cursor-pointer"
            onClick={toggleMenu}
          >
            <FiMenu className="text-xl text-gray-600" />
          </div>

          {/* Title */}
          <h1 className="text-xl font-semibold text-[#373737]">{title}</h1>

          {/* Notification Icon */}
          <div className="relative flex items-center justify-center">
            {/* <FaMagnifyingGlass className="mr-2 size-6" /> */}
            <h1 className="text-black ml-[20px]"> .</h1>
          </div>
        </div>
      </div>

      {/* Slide-in Menu */}
      <div
        style={{
          transform: menuOpen ? "translateX(0)" : "translateX(-100%)",
          transition: "transform 0.3s ease",
        }}
        className="fixed inset-y-0 left-0 w-3/4 max-w-xs bg-gradient-to-b
         bg-[#FFFFFF] text-black shadow-lg z-50 p-6 rounded-tr-lg rounded-br-lg overflow-y-auto"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-black">
            Bem-vindo, PEDRO ESTÁCIO
          </h2>
          <FaTimes className="text-xl text-black" onClick={toggleMenu} />
        </div>
        <ul className="space-y-6">
          <li className="flex items-center">
            <FaWallet className="text-black mr-3" /> {/* Icon for Expenses */}
            <Link to="/expanses" className="text-black text-lg">
              Expenses
            </Link>
          </li>
          <li className="flex items-center">
            <FaAddressBook className="text-black mr-3" />{" "}
            {/* Icon for Expenses */}
            <Link to="/cadstre" className="text-black text-lg">
              Cadastre{" "}
            </Link>
          </li>
          {/* <li className="flex items-center"> */}
          {/* <FaWallet className="text-black mr-3" /> */}
          {/* Icon for Expenses */}
          {/* <Link to="/absenses" className="text-black text-lg">
              Absent
            </Link> */}
          {/* </li> */}
          <li className="flex items-center">
            <FaFileAlt className="text-black mr-3" /> {/* Icon for Expenses */}
            <Link to="/outputs" className="text-black text-lg">
              Outputs
            </Link>
          </li>
          <li className="flex items-center">
            <FaEnvelope className="text-black mr-3" /> {/* Icon for Expenses */}
            <Link to="/communication" className="text-black text-lg">
              Comunication{" "}
            </Link>
          </li>
          <li className="flex items-center">
            {/* <FaWallet className="text-black mr-3" />  */}
            <Link to="/expanses" className="text-black text-lg">
              {/* Expanses */}
            </Link>
          </li>
          {/* Add more menu items here with similar structure */}
        </ul>
      </div>

      {/* Overlay */}
      {menuOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default CommonHeaderWithMenu;
