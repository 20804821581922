import React, { createContext, useContext, useState, useEffect } from "react";

// Create a Context for the language
const LanguageContext = createContext();

// Create a Provider component
export const LanguageProvider = ({ children }) => {
  // Initialize language state from localStorage or default to 'pt'
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("language") || "pt";
  });

  useEffect(() => {
    // Update localStorage whenever language changes
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the LanguageContext
export const useLanguage = () => {
  return useContext(LanguageContext);
};
