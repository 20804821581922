import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // fix import
import FooterNavigation from "../FooterNavigation";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import { useNavigate } from "react-router-dom";

const AddExpense = () => {
  const [formData, setFormData] = useState({
    EMPRESA: "",
    RHID: "",
    DT_ADMISSAO: "",
    DT_INICIO: "",
    DT_FIM: "",
    ESTADO: "Created", // assuming "Created" is a default value
    TOTAL_DESPESA: 2500.75,
    CD_MOEDA: "USD",
    NR_DOCUMENTO: "",
    ANO: 2024,
    MES: 9,
    ID_PERIODO: 1,
    SEQ_: 10,
    CD_RUBRICA: "TRVL",
    CD_ENT_INT: "",
    ID_WKF: 200,
    INSERTED_BY: "",
  });

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);

      setFormData((prevData) => ({
        ...prevData,
        EMPRESA: decodedToken.EMPRESA || "DEMO",
        RHID: decodedToken.RHID,
        DT_ADMISSAO: decodedToken.DT_ADMISSAO || "",
        INSERTED_BY: decodedToken.RHID,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/expenses/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast("Expense created successfully!");
        navigate("/expanses"); // Redirect back to the expenses list
      } else {
        const errorData = await response.json();
        console.error("Error creating expense:", errorData);
        alert("Failed to create expense.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the expense.");
    }
  };

  return (
    <>
      <CommonHeaderWithMenu title="New Expenses" />
      <div className="container mx-auto p-4 flex flex-col justify-between h-screen overflow-y-auto mb-16">
        <h2 className="text-2xl font-semibold mb-4">Create New Expense</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Company:</label>
            <input
              type="text"
              name="EMPRESA"
              value={formData.EMPRESA}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Total Despesa:
            </label>
            <input
              type="number"
              name="TOTAL_DESPESA"
              value={formData.TOTAL_DESPESA}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Admission:</label>
            <input
              type="date"
              name="DT_ADMISSAO"
              value={formData.DT_ADMISSAO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Begin Dt.:</label>
            <input
              type="date"
              name="DT_INICIO"
              value={formData.DT_INICIO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">End Dt.:</label>
            <input
              type="date"
              name="DT_FIM"
              value={formData.DT_FIM}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Document No.:
            </label>
            <input
              type="text"
              name="NR_DOCUMENTO"
              value={formData.NR_DOCUMENTO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">State:</label>
            <select
              name="ESTADO"
              value={formData.ESTADO}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            >
              <option value="Created">Created</option>
              {/* Add other state options here */}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Internal Entity:
            </label>
            <input
              type="text"
              name="CD_ENT_INT"
              value={formData.CD_ENT_INT}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          {/* Continue adding other form fields as needed */}
          <div className="flex justify-end mt-auto">
            <button
              type="button"
              className="mr-4 bg-gray-300 px-4 py-2 rounded"
              onClick={() => navigate("/expanses")}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#8AB53E] px-4 py-2 rounded text-white"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <FooterNavigation />
    </>
  );
};

export default AddExpense;
