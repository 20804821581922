import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const UserOutputs = () => {
  const [outputsData, setOutputsData] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchOutputsData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("You need to be logged in to view this data.");
          return;
        }
        const response = await fetch(`${apiUrl}/api/user/outputs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch outputs data");
        }
        const data = await response.json();
        setOutputsData(data.outputsData);
      } catch (error) {
        console.error("Error fetching outputs data:", error);
        alert("An error occurred while fetching outputs data.");
      }
    };

    fetchOutputsData();
  }, [apiUrl]);

  const toggleAccordion = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        Your Outputs
      </h2>
      {outputsData.length === 0 ? (
        <p>No outputs data available.</p>
      ) : (
        <div className="bg-white shadow-md rounded-lg">
          {outputsData.map((output, index) => (
            <div key={index} className="border-b border-gray-200">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 focus:outline-none"
              >
                <span className="font-medium text-lg text-gray-700">
                  Output Code: {output.CD_OUTPUT}
                </span>
                {expanded === index ? (
                  <FaChevronUp className="h-6 w-6 text-gray-500" />
                ) : (
                  <FaChevronDown className="h-6 w-6 text-gray-500" />
                )}
              </button>
              {expanded === index && (
                <div className="p-6 bg-gray-100">
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>HEO Code:</strong> {output.CD_HEO}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>Year:</strong> {output.ANO}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>Month:</strong> {output.MES}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>Admission Date:</strong>{" "}
                    {new Date(output.DT_ADMISSAO).toLocaleDateString()}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>Company:</strong> {output.EMPRESA}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>RHID:</strong> {output.RHID}
                  </p>
                  <p className="text-sm text-gray-500 mb-2">
                    <strong>Flex Data:</strong> {output.FLEX_1_V1 || "N/A"}
                  </p>
                  {/* You can continue adding more fields as necessary */}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserOutputs;
